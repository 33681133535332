<template>
  <div>
    <div v-if="!this.$hasConstruction('IEBDC:SY:XXFW:BSZN:SFZJ') && $hasPermission('IEBDC:BSZN')">
      <div class="content" v-if="selectedBsdtTitle">
        <el-tabs v-model="activeName" @tab-click="handleClick" :tab-position="tabPosition">
          <el-tab-pane :label="item.name" :name="item.actName" v-for="item in tabData" :key="item.id">
            <div
              class="tab-center"
              v-for="a in item.flowDefConfigs"
              :key="a.id"
              :class="{ active: subActiveColor === a.flowCode }"
              @click="getGuadiance(a)"
            >
              <div>{{ a.name }}</div>
            </div>
          </el-tab-pane>
          <div class="eTableCon" v-show="!showTable">
            <el-table :data="tableData" stripe>
              <el-table-column prop="name" label="流程名称" width="580px"></el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <el-button size="small" type="primary" plain @click.prevent.stop="getBussinessGud(scope.row)"
                    >办事指南</el-button
                  >
                  <el-button size="small" type="default" plain @click="onlineRegister(scope.row)" :disabled="!userData"
                    >在线登记</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="bottom" v-show="showTable">
            <table class="table table-bordered table-bd">
              <tbody>
                <tr>
                  <td class="bluelabel">
                    <label>适用情形</label>
                  </td>
                  <td colspan="7">{{ baseData.description }}</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>承诺时限</label>
                  </td>
                  <td colspan="3">{{ baseData.cnqx }}</td>
                  <td class="bluelabel">
                    <label>法定时限</label>
                  </td>
                  <td colspan="3">{{ baseData.fdqx }}</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>咨询电话</label>
                  </td>
                  <td colspan="3">{{ baseData.zxdh }}</td>
                  <td class="bluelabel">
                    <label>投诉电话</label>
                  </td>
                  <td colspan="3">{{ baseData.tsdh }}</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>预审时限</label>
                  </td>
                  <td colspan="7">{{ baseData.yssx }}工作日</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>受理条件</label>
                  </td>
                  <td colspan="7">{{ baseData.sltj }}</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>收费标准</label>
                  </td>
                  <td colspan="7">{{ baseData.sfbz }}</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>法定依据</label>
                  </td>
                  <td colspan="7">{{ baseData.fdyj }}</td>
                </tr>
                <tr>
                  <td class="bluelabel">
                    <label>申报材料</label>
                  </td>
                  <td colspan="7" class="istable">
                    <table>
                      <thead>
                        <th style="min-width: 100px;">材料名称</th>
                        <th style="width: 100px;">材料形式</th>
                        <th style="min-width: 200px;">材料详细要求</th>
                        <th style="width: 80px;">是否必需</th>
                        <th style="width: 50px;">示例</th>
                      </thead>
                      <tbody>
                        <tr v-for="item in documentList" :key="item.id">
                          <td>{{ item.clmc }}</td>
                          <td>{{ item.clxs }}</td>
                          <td>{{ item.clsm }}</td>
                          <td>{{ item.sfbx }}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <el-button class="btn" type="primary" size="middle" @click="back">返回</el-button>
          </div>
        </el-tabs>
      </div>
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>

    <!--    <div class="content" v-else>-->
    <!--      <div class="yqts">-->
    <!--        友情提示：-->
    <!--        <span>请您先选择办事大厅！↑</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { getFirstFlow, getThirdFlow } from 'iebdc/api/guidance.js';
import { showGuide, showFiles } from 'iebdc/api/visiting-service';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import { localDataUser } from '@iebdc/utils/local-data.js';
import hasConstruction from '@iebdc/mixin/hasConstruction';
export default {
  mixins: [hasConstruction],
  data() {
    return {
      userData: localDataUser.get(),
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '198px',
        imgHeight: '154px',
        marginTop: '-108px',
        title: '敬请期待',
        description: '正在建设中...'
      },
      activeName: '0',
      tabPosition: 'left',
      tabData: [], // 一级流程数据
      tableData: [], // 二级流程数据
      subActiveColor: '',
      baseData: {},
      documentList: [],
      showTable: false,
      djjgCode: '',
      activeIndex: '0', // 用来记录点击的当前的tab页
      secondTypeData: {}, //当前选中一级流程数据存储
      thirdTypeData: {}, // 当前选中的二级流程数据储存
      fourTypeData: {} // 当前选中的三级流程数据存储
    };
  },
  computed: {
    ...mapGetters(['selectedBsdtTitle']),
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem']),
    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    }
  },
  methods: {
    // 保存一级流程数据方法
    ...mapMutations('wysq-data', ['setFirstFlow']),
    init() {
      console.log('chushihua1');
      //获取第一级的数据
      getFirstFlow().then((res) => {
        if (res.success) {
          if (res.success && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].actName = i.toString();
            }
            this.tabData = res.data;
            this.secondTypeData = this.tabData[this.activeIndex];
          }
        }
      }, function() {
        console.log('报错');
      });
    },
    handleClick(activeName) {
      this.baseData = {};
      this.tableData = [];
      this.showTable = false;
      this.activeIndex = activeName.index;
      if (this.tabData) {
        this.secondTypeData = this.tabData[this.activeIndex];
      }
    },
    //获取当前指南列表
    getGuadiance(a) {
      this.thirdTypeData = a;
      this.subActiveColor = a.flowCode;
      if (!this.$store.getters.selectedOrganizationsCode) {
        this.$message.error('请先选择办事大厅');
      }
      let param = {
        djjg: this.$store.getters.selectedOrganizationsCode,
        flowType: IEBDC_USER_DATA().userCategory ? IEBDC_USER_DATA().userCategory : 'NORMAL',
        parentId: a.id
      };
      getThirdFlow(param).then((res) => {
        if (res.success && res.data) {
          this.tableData = res.data.page;
          this.showTable = false;
        }
      });
    },
    // 获取办事指南详情
    getBussinessGud(row) {
      let param = {
        subcfgId: row.id
      };
      Promise.all([showGuide(param), showFiles(param)]).then((res) => {
        if (res[0].success && res[0].data) {
          this.baseData = res[0].data;
        }
        if (res[1].success && res[1].data) {
          for (const item of res[1].data) {
            if (item.sfbx === true) {
              item.sfbx = '是';
            } else {
              item.sfbx = '否';
            }
          }
          this.documentList = res[1].data;
        }
      });
      this.showTable = true;
    },
    // 在线登记
    onlineRegister(row) {
      this.fourTypeData = row;
      let arrayData = [];
      arrayData.push(this.secondTypeData);
      arrayData.push(this.thirdTypeData);
      arrayData.push(this.fourTypeData);
      // 需要赋值的流程key
      const flowKey = ['secondTypeData', 'thirdTypeData', 'fourTypeData'];
      // 保存流程数据
      const flowData = flowKey.reduce((acc, cur, index) => {
        // console.log(acc,'acc',cur,'cur',index,'index----')
        acc[cur] = arrayData[index];
        return acc;
      }, {});
      this.setFirstFlow(flowData);
      //跳转我要申请页面的阅读须知
      this.$router.push({
        name: 'read',
        query: {
          status: true,
          flowData
        }
      });
    },
    back() {
      this.showTable = false;
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
@import 'src/styles/common-variables';
@import 'src/styles/public';

.content {
  min-height: 400px;
  line-height: 21px;
  position: relative;
  .yqts {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 30px;
    font-size: 30px;
    font-weight: 700;

    span {
      color: #67c23a;
    }
  }
}
.el-tab-pane {
  display: flex;
  align-items: center;
  justify-content: center;
  .tab-center {
    letter-spacing: 1px;
    padding: 11px 20px;
    text-align: center;
    //height: 40px;
    background-color: #f1f2f3;
    margin: 10px;
    cursor: pointer;
  }
  .active {
    background-color: #50a2ec;
    color: #fff;
  }
}
.bottom {
  // text-align: center;
  //带边框的表格
  .table {
    //line-height: 24px;
    padding-top: 10px;
    //border: 1px solid #c9deef;
    //border-collapse: collapse;
  }
  .table-bd {
    color: #353535;
    height: 100%;
    tr {
      height: 38px;
      td {
        padding: 8px;
        border: 1px solid #dddddd;
        a {
          cursor: pointer;
        }
      }
    }
    > td {
      width: 25%;
      vertical-align: middle;
    }
    .bluelabel {
      width: 12.5%;
      text-align: center;
      background-color: #eaf1f9;
      vertical-align: middle;
      label {
        margin: 0px;
        font-weight: 700;
      }
    }
    .istable {
      padding: 0px;
      table {
        width: 100%;
        border: none;
        border-collapse: collapse;
        th {
          // border: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
          padding: 8px;
          background-color: #eaf1f9;
        }
        td {
          border: none;
          border-right: 1px solid #dddddd;
          border-top: 1px solid #dddddd;
        }
        th:last-child {
          border: none;
        }
      }
    }
  }
  .btn {
    // text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
}
/deep/ .el-tabs {
  .el-tabs__nav-wrap {
    text-align: center;
    .el-tabs__nav-scroll {
      display: inline-flex;
      margin: 0 auto;
    }
  }
}
</style>
